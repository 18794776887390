.header {
  z-index: 10;
}

.header__inner {
  justify-content: center;
  text-align: center;
}

.header__img {
  margin: $spacing-base auto $spacing-large;
  filter: grayscale(255);
  width: 75%;

  @include media($md-up) {
    width: 50%;
  }
  
  @media screen and (orientation : landscape) {
    width: 25%;
  }
}

.header__title {
  width: 100%;
}