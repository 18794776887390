.project {
  @include span-columns(11);
  float: none;
  margin: auto;
  padding: $spacing-base;

  &:last-of-type {
    margin: auto;
  }
}

.project__thumb-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 6%;
  margin-top: 8%;
  
  // Stripes
  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 12%;
    right: -10%;
    bottom: -7%;
    left: -7%;
    background: $pattern-stripes-six;
    opacity: 1;
    
    transition: all $animation-base-duration;

    .project:hover &, .project:focus & {
      opacity: 0;
      top: 0%; //-119%
      right: -103%;
      left: 100%;
      bottom: 100%;
    }
  }

  // Filled circle
  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    top: -8%;
    right: 8%;
    bottom: 8%;
    left: -8%;
    border-radius: 50%;
    background-color: $color-primary;
    
    transition: all $animation-base-duration,
                background-color .05s;

    .project:hover &, .project:focus & {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $color-secondary;
    }
  }
}

// Outline circle
.project__thumb-circle {
  z-index: -1;
  position: absolute;
  top: 7%;
  right: -7%;
  bottom: -7%;
  left: 7%;
  border-radius: 50%;
  border: 2px solid $color-primary;
  
  transition: all $animation-base-duration,
              border-color .05s;

  .project:hover &, .project:focus & {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-color: $color-secondary;
  }
}

.project__thumb {
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 2px solid $color-dark;
  filter: grayscale(100%);
    
  transition: all $animation-base-duration;

  .project:hover &, .project:focus & {
    border-color: $color-secondary;
    filter: grayscale(0%);
  }
}