@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,900');

// Typography
$font-family-regular: 'Source Sans Pro', serif;
$font-family-heading: 'Source Sans Pro', sans-serif;

$font-weight-regular: 600;
$font-weight-heading: 900;

// Font Sizes
$font-size-base: 1rem;
$font-size-larger: 1.5rem;
$font-size-large: 1.25rem;
$font-size-small: .4rem;
$font-size-smaller: .2rem;
$font-size-button: 1.0625rem;

// Line height
$line-height-base: 1.7;
$line-height-heading: 1;

// Heading scale
$heading-scale: 1.21;

$font-responsive-base: .5vw;