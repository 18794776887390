.footer {
}

.footer__link {
  position: fixed;
  z-index: 15;
  border: $border-base;
  padding: 0 2px;

  &[class*='top'] {
    top: 0;
    margin-top: $spacing-smaller;
  }

  &[class*='right'] {
    right: 0;
    margin-right: $spacing-smaller;
  }

  &[class*='bottom'] {
    bottom: 0;
    margin-bottom: $spacing-smaller;
  }

  &[class*='left'] {
    left: 0;
    margin-left: $spacing-smaller;
  }
}

.footer__link-icon {
  padding: 0 5px 0 4px;
  margin: 0;
  display: block;

  &--active {

  }

  &--inactive {

  }

  &.is-hidden {
    display: none;
  }

  svg {
    padding-top: 2px;
  }
}

.footer__link-text {
}