/**
 * Button classes
 */

.btn, button {
  @include btn;
  color: $color-light;
  border: 5px solid $color-light;
  background: $color-primary;

  &:hover, &:focus {
    color: $color-primary;
    background: $color-light;
  }
}

.btn-secondary {
  @include btn;
  color: $color-light;
  border: 5px solid $color-light;
  background: $color-secondary;

  &:hover, &:focus {
    color: $color-secondary;
    background: $color-light;
  }
}

//.btn-primary {
//@include btn;
//@include btn-primary;
//}

//.btn-secondary {
//@include btn;
//@include btn-secondary;
//}

//.btn-tertiary {
//@include btn;
//@include btn-tertiary;
//}

.btn-ghost {
  @include btn;
  @include btn-ghost;
  color: $color-primary;
  border: 5px solid $color-primary;
  background: $color-light;

  &:hover, &:focus {
    color: $color-light;
    background: $color-primary;
  }
}

.btn-ghost-secondary {
  @include btn;
  @include btn-ghost;
  color: $color-secondary;
  border: 5px solid $color-secondary;
  background: $color-light;

  &:hover, &:focus {
    color: $color-light;
    background: $color-secondary;
  }
}

//.btn-ghost-primary {
//@include btn;
//@include btn-ghost;
//@include btn-ghost-primary;
//}

//.btn-ghost-secondary {
//@include btn;
//@include btn-ghost;
//@include btn-ghost-secondary;
//}

//.btn-ghost-tertiary {
//@include btn;
//@include btn-ghost;
//@include btn-ghost-tertiary;
//}

//.btn-sm {
//@include btn-sm;
//}

//.btn-clean {
//@include btn-clean;
//}
