ul,
ol {
  padding-left: 25px;
}

dl {
  margin-bottom: $spacing-small;

  dt {
    font-weight: 600;
    margin-top: $spacing-small;
  }

  dd {
    margin: 0;
  }
}
