table {
  border-collapse: collapse;
  margin: $spacing-small 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid shade($color-base-border, 25%);
  font-weight: 600;
  padding: $spacing-small 0;
  text-align: left;
}

td {
  border-bottom: $border-base;
  padding: $spacing-small 0;
}

tr,
td,
th {
  vertical-align: middle;
}
