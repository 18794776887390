.unfold {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: $color-light;
  border-radius: 50%;
  transition: all $animation-long-duration $animation-ease-out;
  box-shadow: 0 0 15px -5px transparentize($color-dark, .5);
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 0;
  
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  
  &.is-unfolded, &:target {
    width: 200%;
  }
}

.unfold__inner {
  width: 50%;
  max-height: 100vh;
  position: absolute;
  padding: $spacing-small;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  overflow: hidden;
  
  .is-unfolded &, .unfold:target & {
    overflow-y: scroll;
  }

  @include media($md-up) {
    padding: $spacing-base;
  }
}