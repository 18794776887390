/**
 * General site components
 */

@import 'header';
@import 'footer';
@import 'main';
@import 'projects';
@import 'project';
@import 'about';
@import 'filter';
@import 'unfold';
