.img__wrapper {
  @include span-columns(8);
  float: none;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0;
}

.img__wrapper::after {
  content: '';
  display: block;
  padding-top: 100%;
}

.img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}